<template>
  <div class="adminNav">
    <el-container class="admin" style="width: 100%; height: 100%">
      <el-aside width="200px">
        <div style="height: 60px" class="logo">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <el-menu
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          style="width: 100%; border: 0; background: #f6f8fc"
          text-color="#666666"
          :default-active="$route.path"
        >
          <label v-for="Aitem in menuList" :key="Aitem.id" style="width: 100%">
            <el-menu-item
              style="text-align: left"
              @click="enter(Aitem)"
              :index="'/' + Aitem.route"
            >
              <i :class="Aitem.icon" style="margin-right: 1rem"></i>
              <span slot="title" style="font-size:1rem">
                {{ Aitem.remark }}
              </span>
            </el-menu-item>
          </label>
        </el-menu></el-aside
      >
      <el-container>
        <el-header>
          <p class="top">
            <span style="margin-left:16px">
              <!-- <img src="@/assets/position.png" alt="" /> -->
              {{ position }}
            </span>
            <span style="margin-left: auto; margin-right: 2%">
              <el-button
                size="small"
                type="text"
                style="color:#2370EB"
                @click="Quit"
                >退出</el-button
              >
            </span>
          </p>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "adminNav",
  data() {
    return {
      menuList: [],
      He: true,
      activeIndex: "",
      colin: -1,
      circleUrl: "",
      nickName: "",
      position: "组织管理",
      menuList: [
        {
          remark: "组织管理",
          route: "adminframe",
        },
        {
          remark: "角色管理",
          route: "adminrole",
        },
      ],
    };
  },
  methods: {
    Nav() {
      var menuListSession = JSON.parse(sessionStorage.getItem("menuList"));
      if (menuListSession == null || menuListSession == "") {
        this.$router.push("/");
      } else {
        this.menuList = this.$Tree.treeDataTranslate(menuListSession);
      }
    },
    handleOpen(key, keyPath) {
      this.He = false;
    },
    handleClose(key, keyPath) {
      this.He = true;
    },
    enter(item) {
      if (this.$route.path != "/" + item.route) {
        this.$router.push("/" + item.route);
        sessionStorage.setItem("row", JSON.stringify(item));
      }
      this.colin = item;
    },
    Quit() {
      sessionStorage.clear();
      this.$router.push("/login");
    },
  },
  watch: {
    $route: {
      handler(val, oldVal) {
        if (val.path == "/adminframe") {
          this.position = "组织管理";
        } else if (val.path == "/adminrole") {
          this.position = "角色管理";
        }
        this.activeIndex = val.path.slice(1);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.adminNav {
  width: 100%;
  height: 100%;
  .logo {
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin-bottom: 20px;
  }
  .el-header {
    color: #333;
    background: #fff;
    padding: 0;
    border-bottom: 1px solid #edf0f8;
    .top {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      font-size: 1rem;
      img {
        margin-right: 0.5rem;
      }
      span:nth-child(1) {
        display: flex;
        align-items: center;
      }
    }
  }
  .el-aside {
    background-color: #1f2e54;
    color: #333;
    height: 100%;
  }
  .el-main {
    margin: 0;
    padding: 16px 16px 0 16px;
    background: #f0f2f4;
  }
  .el-menu-item {
    background: #1f2e54;
    color: #fff !important;
    border-radius: none;
  }
  .el-menu-item.is-active {
    background: #182445 !important;
    color: #ffffff;
  }
  .el-menu-vertical-demo {
    background: #1f2e54 !important;
  }
}
</style>
